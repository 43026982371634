import { useEffect, useState } from "react";

interface Error {
    err: boolean;
    msg?: string;
}
interface AlertProps {
    captchaErr: Error;
}
export const Alert = ({captchaErr}: AlertProps) => {

    const [showAlert,setShowAlert] = useState<Boolean>(false);
    useEffect(() => {
        setShowAlert(captchaErr?.err)
    }, [captchaErr])

    return (
        <>{showAlert && 
            <div className="p-5 border-solid border-errField border-[1px] rounded-md text-errMsg bg-errInput mb-6" >
                <svg aria-hidden="true" className="lexicon-icon lexicon-icon-exclamation-full " viewBox="0 0 512 512">
                    <path className="lexicon-icon-outline" d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M256,384c-17.7,0-32-14.3-32-32
                        s14.3-32,32-32s32,14.3,32,32S273.7,384,256,384z M272,288h-32l-16-160h64L272,288z"></path>
                </svg>
                <span className="ml-3">
                    {captchaErr?.msg}
                </span>
                <button className="float-right" onClick={() => {
                    setShowAlert(false)
                }}>
                    <svg className="lexicon-icon lexicon-icon-times" focusable="false" role="presentation" viewBox="0 0 512 512">
                        <path className="lexicon-icon-outline" d="M295.781 256l205.205-205.205c10.998-10.998 10.998-28.814 0-39.781-10.998-10.998-28.815-10.998-39.781 0l-205.205 205.205-205.205-205.238c-10.966-10.998-28.814-10.998-39.781 0-10.998 10.998-10.998 28.814 0 39.781l205.205 205.238-205.205 205.205c-10.998 10.998-10.998 28.815 0 39.781 5.467 5.531 12.671 8.265 19.874 8.265s14.407-2.734 19.907-8.233l205.205-205.238 205.205 205.205c5.5 5.5 12.703 8.233 19.906 8.233s14.407-2.734 19.906-8.233c10.998-10.998 10.998-28.815 0-39.781l-205.238-205.205z"></path>
                    </svg>
                </button>
            </div>
}
</>
    )
}