import React, { useEffect, useState } from 'react';
import './App.css';
import { ApolloProvider, useQuery } from '@apollo/client';
import { Route, Routes, Navigate } from 'react-router-dom';
import client from './services/client';
import { getMenuList } from './graphql/queries/getHeader';
import { tags, homePaths } from "./config/config";

import { Footer } from './components/footer';
import { Header } from './components/header';
import { BusinessComposePage } from './screens/businessComposePage';
import { NotFoundContainer } from './components/pageNotFound';
import { ErrorContainer } from './components/errorPage';
import { pageErrorMessage } from './config/config';
import {ErrProps} from '../src/components/helpers/types'
import { BlockLoader } from './components/blockLoader';

let Body = () => {
  const [menuArr, setMenuArr] = useState<any>()
  const { data, error,loading } = useQuery(getMenuList, {
    variables: {
      usePreview: false,
      tags: tags.globalMenu
    }
  });
  useEffect(() => {
    if (data) {
      const menus = data?.BusinessMenuList?.[0]?.menuSections
      let menuArray: any = []
      menus?.forEach((item: any) => {
        if (item?.sectionLinks !== null) {
          menuArray = menuArray.concat(item?.sectionLinks)
        } else {
          menuArray.push(item)
        }
        return menuArray;
      })
      setMenuArr(menuArray)
    }
    
  }, [data])

  if(error) {
    return <ErrorContainer errorMessage={pageErrorMessage as ErrProps} />
  }
  return (
    <div>
      {loading ? <BlockLoader /> : 
        <Routes>
          
          <Route path="/home" element={<>
            <BusinessComposePage />
          </>} />
          {data && menuArr && menuArr?.map((item: any) => {
            return (
              <Route
                path={`${item.itemUrl}`}
                element={
                  <>
                    <BusinessComposePage />
                  </>
                }
              />
            )
          })}
          {homePaths?.map((item: string) => {
            return <Route path={item} element={<Navigate replace to="/home" />} />
          })}
          {data && menuArr && <Route path="*" element={<NotFoundContainer redirectURL='/' label='Go Home' />} />}
      
        </Routes>
      }
    </div>
  )
}


function App() {
  return (
    <ApolloProvider client={client}>
      <Header />
      <Body />
      <Footer />
    </ApolloProvider>
  );
}

export default App;
