

type clickFunction = () => void;
interface ButtonProps {
    text?: string;
    onClickButton?: clickFunction;
}
export const Button = ({ text, onClickButton }: ButtonProps) => {
    return (
        <div className="absolute right-0  pb-[10%]">
        <button type="button" className=" text-whiteText bg-blue 
            font-semibold
            rounded-full text-lg px-8 py-2.5 text-center 
            me-2 mb-2 "
            onClick={onClickButton}>
            {text}
        </button>
        </div>
    )
}