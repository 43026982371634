import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { getMenuList } from './../../graphql/queries/getHeader';
import { useEffect, useState } from 'react';
import { tags } from '../../config/config';

//common footer element
let Footer = () => {
    const [footer, setFooter] = useState([])
    const [copyright, setCopyright] = useState("")
    const { data } = useQuery(getMenuList, {
        variables: {
            usePreview: false,
            tags: tags.businessFooter
        }
    });

    useEffect(() => {
        if (data) {
            let list = data?.BusinessMenuList[0]
            setFooter(list?.menuSections)
            setCopyright(list?.footerCopyright?.content?.[0]?.content[0]?.value)
        }
    }, [data])

    useEffect(() => {
        // https://shielded.co.nz/
        let shielded = (window as any).ds07o6pcmkorn;
        if (shielded) {
            let frameName = new shielded({
                openElementId: '#shielded-logo',
                modalID: "modal",
            });
            frameName.init();
        }
    }, []);
    return (
        <>
            {/* Desktopview code start */}
            {
                <div className="bg-bgColor mt-[4%] p-4 hidden md:flex flex-col px-14">
                    <div className=" w-full flex space-x-6 text-whiteText flex-wrap  mt-3 ml-3">
                        <div className="cursor-pointer space-x-10 tracking-wider leading-tight">{copyright}</div>
                        {(footer && footer.length > 0) && footer.map((val: any) => {
                            return (
                                <div className="cursor-pointer tracking-wider leading-tight" style={{ fontSize: "16px" }}
                                    key={val.publicName}>
                                    <Link to={val.itemUrl}>{val.publicName}</Link>
                                </div>
                            )
                        })}
                    </div>
                    <div className="flex justify-center py-12">
                        <img
                            alt="shielded"
                            id="shielded-logo"
                            style={{ cursor: 'pointer', margin: '0px auto', display: 'inherit' }}
                            src="https://shielded.co.nz/img/custom-logo.png" width={60} height={60}></img>
                    </div>
                </div>
            }
            {/* Desktop view code end */}

            {/* Mobile View code start */}
            <div className="md:hidden bg-bgColor p-4">
                <div className="grid grid-cols-2 gap-3 justify-center w-full flex  text-whiteText flex-wrap text-base mt-6">
                    {(footer && footer.length > 0) && footer.map((val: any) => {
                        return (
                            <div className="cursor-pointer tracking-wider leading-tight" key={val.publicName}>
                                <Link to={val.itemUrl}>{val.publicName}</Link>
                            </div>
                        )
                    })}

                </div>
                <div className="cursor-pointer flex justify-center text-whiteText flex-wrap text-base mt-3">{copyright}</div>
                <div className="flex justify-center py-12">
                    <img                        
                        alt="shielded"
                        id="shielded-logo"
                        style={{ cursor: 'pointer', margin: '0px auto', display: 'inherit' }}
                        src="https://shielded.co.nz/img/custom-logo.png" width={60} height={60}></img>
                </div>
            </div>
            {/* Mobile view code end */}
        </>
    )
}

export { Footer }