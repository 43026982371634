
import { Document } from '@contentful/rich-text-types';
import { ComponentCard } from "../componentCard"

interface imageFile {
    file: {
        url?: string;
        fileName?: string;
    }
    title?: string;
    description?: string;
}
interface link {
    linkText?: string;
    linkUrl?: string;
    linkDocument?: imageFile;
}
interface componentCard {
    internalName?: string;
    imageUrl: imageFile;
    backgroundGradientColor?: string;
    imagePosition?: string;
    linkText?: string;
    imageLinkDocument: imageFile;
    imageLinkUrl?: string;
    cardTitle?: string;
    cardDescription: Document;
    links?: link[];
}
interface cardProps {
    displayTile: string;
    card?: componentCard[];
    section?: any;
    slug?: string;
}

export const ComponentCardCollection = ({ card, displayTile, section, slug }: cardProps) => {
    
    let displayCss: string;
    switch (displayTile.replace(' ', '')) {
        case 'Twotile': displayCss = "grid-cols-2 gap-0";
            break;
        case 'Threetile': displayCss = "grid-cols-3 gap-4";
            break;
        case 'Fourtile': displayCss = "grid-cols-4 gap-0";
            break;
        default: displayCss = 'grid-cols-1 gap-0';
            break;
    }

    return (
        <>
            {/* {(slug === "/signage") && <h1 className={`bg-compSecTitleBg px-[5%] py-[1%] font-bold text-left text-[2.5rem]`}>
                {section?.internalName?.toUpperCase()}
            </h1>
            } */}
           <div className='ml-[2%] mr-[4%]'>
                {/* (slug !== "/signage" && slug !== "/my-sky" && slug !== "/associations") &&  */
                section?.componentSectionTitle &&  <h2 className={`pl-7 font-bold text-left text-[2.5rem] py-[2%]`}>
                    {/* {(slug !== "/marketing-material")?section?.internalName:section?.componentSectionTitle} */}
                    {section?.componentSectionTitle}
                </h2>
                }
                {card && card.length > 0 && (
                    <div className={`ml-[2%] grid lg:${displayCss}`}>
                        {card.map((element: componentCard) => {
                            return (<ComponentCard
                                card={element}
                                displayTile={displayTile}
                            />)
                        })}
                    </div>)}
            </div>
        </>
    )
}