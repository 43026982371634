import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { getMenuList } from './../../graphql/queries/getHeader';
import { tags } from "../../config/config";
import { Breadcrumb } from "../breadcrumb";
import { breadCrumpPath } from "../../config/config";
import { BlockLoader } from "../blockLoader";

interface Props {
  value: String;
  mouseHover: any;
  mouseRemoved: any;
}

interface menu {
  itemUrl?: string;
  itemIcon?: string;
  publicName?: string;
}

interface breadcrumb {
  value?: string;
  url?: string;
}

interface MenuProps {
  menu: Array<String>;
  mouseHover: any;
  mouseRemoved: any;
  onClickSubmenu: any;
}

// interface HeaderProps {
//   path?: string;
// }

const AppendArrow = ({ value, mouseHover, mouseRemoved }: Props) => {
  return (
    <div className="flex items-center" onMouseOver={mouseHover} onMouseLeave={mouseRemoved}>
      <span >{value}</span>
      <span className="mt-1"> <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
      </svg></span>
    </div>
  )
}

// Dropdown menu data
const DropDownMenu = ({ menu, mouseHover, mouseRemoved, onClickSubmenu }: MenuProps) => {
  return (
    <div className="-translate-x-1/4 translate-y-2 shadow-[0_1px_5px_-1px_rgba(0,0,0,0.3)] absolute z-50 bg-whiteText opacity-90">
      <ul onMouseOver={mouseHover} onMouseLeave={mouseRemoved}>
        {menu && menu.map((val: any) => {
          return (
            <li className="py-2 px-12 text-center opacity-100 text-sm text-darkText hover:text-hoverTextColor" key={val.publicName}>
              <a href={val.itemUrl} onClick={() => { onClickSubmenu(val.itemUrl) }}>{val.publicName}</a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

let Header = () => {
  let { pathname } = useLocation();
  let path = pathname
  const navigate = useNavigate()
  const { data, loading } = useQuery(getMenuList, {
    variables: {
      usePreview: false,
      tags: tags.businessHeader
    }
  });
  useEffect(() => {
    if (data) {
      processApiRes(data?.BusinessMenuList[0]?.menuSections)
    }
  }, [data])

  const [menuFlag, setMenuFlag] = useState({ id: "", val: false })
  const [menu, setmenu] = useState([])
  const [icon, setIcon] = useState<string>()
  const [breadcrumb, setBreadcrumb] = useState<breadcrumb[]>()
  let [showHambMenu, setShowHambMenu] = useState(false)
  const [routes, setRoutes] = useState<Array<String>>(breadCrumpPath)

  const onClickSubmenu = (value: string) => {
    menu.map((val: any) => {
      if (val.subMenu) {
        val?.subMenu?.filter((item: menu) => item?.itemUrl === value)
          ?.map((item: menu) => {
            return setBreadcrumb([{ value: "Sky Business", url: "/" }, { value: val.menu, url: "/" }, { value: item.publicName, url: item.itemUrl }])
          })
      }return ''
    })
    if(value === "/sky-survivor"){
      return setBreadcrumb([{ value: "Sky Business", url: "/" }, { value: "Sky Services", url: "/" }, { value: "Sky Survivor", url: "/sky-survivor" }])
    }else if(value === "/power-reset-steps"){
      return setBreadcrumb([{ value: "Sky Business", url: "/" }, { value: "Support", url: "/" }, { value: "Power Reset Steps", url: value }])
    }else if(value === "/our-customers"){
      return setBreadcrumb([{ value: "Sky Business", url: "/" }, { value: "Support", url: "/" }, { value: "Our Customers", url: value }])
    }else if(value === "/temporary-venue-finder") {
      return setBreadcrumb([{ value: "Sky Business", url: "/" }, { value: "Support", url: "/" }, { value: "Temporary Venue Finder", url: value }])
    }
  }
  const processBreadcrumbs = (path: string) => {
    if (path && path !== "/" && menu && routes.includes(path)) {
      onClickSubmenu(path)
    } else if ((menu && path === "/") || !routes.includes(path) ) {
      setBreadcrumb([{ value: "Sky Business", url: "/" }])
    }
  }
  useEffect(() => {
    if (path && menu && !path.includes('terms-of-use') && !path.includes('commercial-terms')
    && !path.includes('content-protection') && !path.includes('privacy-policy')){
      processBreadcrumbs(path)
    }
  }, [path, menu])

  const onClickBreadcrumb = (path: string) => {
    processBreadcrumbs(path)
    navigate(path)
  }


  const processApiRes = (data: any) => {
    let menuList: any = []
    let tmpRoute = routes
    data?.forEach((val: any) => {
      if (val.sectionName !== null) {
        let headerTmp: any = {
        }
        headerTmp.menu = val.sectionName
        let subMenu: any = []
        val.sectionLinks.forEach((item: any) => {
          tmpRoute = [...tmpRoute,item.itemUrl]
          return subMenu.push(item)
        })
        headerTmp.subMenu = subMenu
        menuList.push(headerTmp)
      } else {
        let headerTmp: any = {

        }
        headerTmp.itemUrl = val.itemUrl
        headerTmp.publicName = val.publicName
        if (val.itemIcon) {
          headerTmp.itemIcon = val.itemIcon;
          setIcon(val.itemIcon)
        }
        menuList.push(headerTmp)
      }
      return menuList
    })
    setRoutes(tmpRoute)
    setmenu(menuList)
  }
  const handleMouseHover = (value: string) => {
    setMenuFlag({ id: value, val: true })
  }

  const handleMouseLeave = (value: string) => {
    setMenuFlag({ id: value, val: false })
  }

  return (
    <>
    {loading ? <BlockLoader / > :
    <>
    {/* script tag for gtag */}
    {data?.BusinessMenuList[0]?.segmentSnippet && 
    <Helmet>
      <script>{data?.BusinessMenuList[0]?.segmentSnippet}</script>
    </Helmet>
}
      <div className="bg-bgWhite sticky top-0 w-full z-40 desk:px-14 " id="header">

        {/*  Mobile view */}
        <div className="md:hidden sticky top-0 z-50  flex items-center justify-between px-4 py-1">
          <div className="text-left w-2/6 cursor-pointer">
            <button
              className="w-10 h-10 relative text-blue-darker top-3px"
              onClick={() => setShowHambMenu(!showHambMenu)}
            >
              <span>
                <img width={25} alt="hamburger_menu" src="data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%236B6C7E' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"></img>
              </span>
            </button>
          </div>

          <div className="cursor-pointer w-2/6 flex justify-center">
          <Link to={'/'}>
            <img src={icon} alt="header_logo" width={70} />
            </Link>
          </div>

          <div className="w-2/6 text-right text-nowrap font-[MarkPro-Bold SKY]">
            {menu && menu.map((item: any, index: any) => (
              item.itemUrl && item.itemUrl.indexOf('tel') > -1 && !showHambMenu &&
                  <a key={index} className="desk:text-xl items-center max-tab:text-[0.8125rem] text-darkText font-semibold  cursor-pointer hover:text-hoverTextColor" href="/" >{item.publicName}</a>
            ))}
          </div>
        </div>
        {
          showHambMenu && (
            <div className="w-full h-screen font-normal">
              <ul className="absolute z-1 overflow-y-auto h-[75vh] leading-[1.2]">
                {menu && menu?.map((val: any, i: any) => (
                   (val.itemUrl !== null && val.menu) &&
                      <>
                        <li className="pl-14 text-darkText" key={val.menu}>
                          <div className="flex items-center pt-6 text-[19px]" >
                            <span >{val.menu}</span>
                            <span className="mt-1"> <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                            </svg></span>
                          </div>
                          <ul className="pl-6 text-[15px]">
                            {val.subMenu.map((val2: any) => {
                              return (
                                <li className="pt-4" onClick={() => setShowHambMenu(false)} ><a href={val2.itemUrl} key={val2.publicName}>{val2.publicName}</a></li>
                              )
                            })}
                          </ul>
                        </li>
                      </>                  
                ))}
              </ul>
            </div>
          )
        }

        {/*  Desktop view */}

        <div className="justify-between items-center px-3 py-3 hidden md:flex">
          <div className="font-normal cursor-pointer" onClick={() => { }}>
            <Link to={'/'}>
              <img src={icon} alt="header_logo" width={68}></img>
            </Link>
          </div>
          <div className="flex items-center relative">
            {menu && menu?.map((val: any, i: any) => {
              if (val.itemUrl !== null && val.menu) {
                return (
                  <div onMouseOver={() => handleMouseHover(val.menu)} 
                  className="text-[16px] items-center mr-3 rounded box-border p-1 border-solid border-2 border-transparent hover:border-darkText text-darkText  font-normal  cursor-pointer hover:text-hoverTextColor" >
                    <AppendArrow value={val.menu} mouseHover={() => handleMouseHover(val.menu)}
                      mouseRemoved={() => handleMouseLeave(val.menu)} />
                    {(menuFlag.val && menuFlag.id === val.menu) && <DropDownMenu menu={val.subMenu}
                      mouseHover={() => handleMouseHover(val.menu)}
                      mouseRemoved={() => handleMouseLeave(val.menu)}
                      onClickSubmenu={onClickSubmenu} />}
                  </div>
                )
              } else if (i > 0) {
                return <a className="text-base items-center text-darkText  font-bold  cursor-pointer hover:text-hoverTextColor" href="/" >{val.publicName}</a>
              }
            })}
          </div>
        </div>
      </div>
      {/* breadcrumb */}
      <div>
        {breadcrumb && path && <Breadcrumb value={breadcrumb} onClickBreadcrumb={onClickBreadcrumb} />}
      </div>
      </>
    }
    </>

  )
}

export { Header }