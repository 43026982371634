import React from 'react';

import loading from '../assets/loading.gif';

interface LoaderProps {
  bgOpacity?: boolean;
  bgColor?: string;
}

export let Loader = ({ bgOpacity = true, bgColor = 'bg-navy' }: LoaderProps) => {
  const colorText = `bg-opacity-50 ${bgColor}`;
  const className = `w-full h-full z-5 absolute ${ [colorText]} : ${bgOpacity} }`;
  return (
    <div className={className}>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <img className="w-24 opacity-75" src={loading} alt="loading" />
      </div>
    </div>
  );
};
// loader for graphql calls that displays the loading image when the response has not yet recieved
export const BlockLoader = ({ className, ...rest }: React.HTMLProps<HTMLDivElement>) => {
  let className_ = `w-full h-96 relative ${className}`;
  return (
    <div className={className_} {...rest}>
      <Loader bgOpacity={false} />
    </div>
  );
};
