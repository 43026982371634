
import { useEffect } from 'react';

const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL
const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY

const BACKEND_ENDPOINT = process.env.REACT_APP_ENDPOINT
const BEARER_TOKEN = process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN;

  const tags ={
    businessFooter: "businessFooter",
    businessHeader: "businessHeader",
    globalMenu: "businessGlobalMenu"
} 

 const contentTypes = {
    popupCard : "businessPopupCardCollection",
    componentCard: "businessComponentCard",
    componentCardCollection: "businessComponentCardCollection"
}

 const homePaths = [
  "/home",
  "/your-business",
  "/sky-services",
  "/promotion",
  "/support"
]
const breadCrumpPath = [...homePaths,"/sky-survivor","/our-customers", "/power-reset-steps", "/temporary-venue-finder"]
 const defaultTitle = {
    title: "Home - Sky Business"
}

 const useDocumentTitle = (pageTitle?: string) => {
  useEffect(() => {
    if (pageTitle) document.title = pageTitle;
    return () => {
      // Change title to default sky business home page title
      document.title = defaultTitle.title;
    };
  }, [pageTitle]);
};

const pageErrorMessage = {
  headerText: 'Snap!',
  lineOne: 'Looks like something went wrong',
  lineTwo: 'Try again later...',
};


const gradients = [
  "sky-blue-gradient",
  "sky-green-gradient",
  "sky-orange-gradient"
]

const camelCase = (str: string) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}


const formEmailPayload = (val:any) => {
  let payload: any={}
  val.forEach((item:any) => {
    if(item.key && !item.key.includes("help") && !item.key.includes("submit")
      && !item.key.includes("captcha") && !item.key.includes("option") 
    && !item.key.includes("empty")){
        let key = camelCase(item.value);
        if(key ===  "posterSelection")
          payload[key] = item.inpValue?.join(', ')
        else
        payload[key] = item.inpValue?item.inpValue:""
    }
if(item.key === "captchaToken"){
  payload.captcha = item.token
}
  })
  console.log("payloaddd", payload)
  return payload;
}

export {
  tags,
  contentTypes,
  homePaths,
  breadCrumpPath,
  defaultTitle,
  useDocumentTitle,
  pageErrorMessage,
  gradients,
  GRAPHQL_URL,
  BACKEND_ENDPOINT,
  formEmailPayload,
  BEARER_TOKEN,
  CAPTCHA_KEY,
}
