import { gql } from '@apollo/client';
import { BusinessMenuList } from '../fragments/header';

export const getMenuList = gql`
${BusinessMenuList}
  query GetMenuList($usePreview: Boolean, $tags:String) {
    BusinessMenuList(usePreview: $usePreview, tags: $tags) {
    ...BusinessMenuList 
    }
  }
`;
