import axios from 'axios'
import { BACKEND_ENDPOINT } from '../config/config';

// const url = "https://web-proxy.dev.skydev.co.nz/dev"
// const url = "http://localhost:4000"
const url = BACKEND_ENDPOINT
let agent = axios.create({ baseURL: url });
const headerChannelInterceptor = (channel: string) => async (config: any) => {

  config.headers['channel'] = channel;
  return config;
};
agent.interceptors.request.use(headerChannelInterceptor('WEB'));
// let instance = axios.create({
//   headers: {
//     post: {        // can be common or any other method
//       channel: 'WEB'
//     }
//   }
// })

interface Payload {
  name?: string;
  businessName?: string;
  accountNumber?: string;
  emailAddress?: string;
  phoneNumber?: string;
  message?:string;
}
async function sendEmail(payload: Payload): Promise<any> {
  try {
    // const url = "http://localhost:4000/businessSendMail";
    const response = await agent.post<any>('/businessSendMail', payload)
    return response?.data;
  } catch (err) {
    return [];
  }
}

export { sendEmail }