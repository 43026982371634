import { ApolloClient, createHttpLink,InMemoryCache } from '@apollo/client';
import {GRAPHQL_URL, BEARER_TOKEN} from '../config/config'

import { setContext } from '@apollo/client/link/context';
const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization:  `Bearer ${BEARER_TOKEN}` ,
      }
    }
  });
  const httpLink = createHttpLink({
    // uri: 'http://localhost:4000/graphql',
    // uri: 'https://web-graphql.dev.sky.co.nz/dev/graphql',
    uri: GRAPHQL_URL,
  });

//   const graphqlUrl = "https://graphql.contentful.com/content/v1/spaces/r67umy19lt91/environments/dev"
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });
  


export default client