import { gql } from '@apollo/client'

export const BusinessMenuList = gql`
fragment BusinessMenuList on BMenuList {
    internalName
    footerCopyright
    segmentSnippet
    menuSections{
      itemUrl
      itemIcon
      publicName
      sectionName
      slug
     sectionLinks{
      itemUrl
      publicName
    }
    }
}
`;
