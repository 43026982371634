// import { useNavigate } from "react-router-dom";
import { Document } from '@contentful/rich-text-types';
import { DisplayContent } from "../displayContent";

interface imageFile {
    file: {
        url?: string;
        fileName?: string;
    }
    title?: string;
    description?: string;
}
interface link {
    linkText?: string;
    linkUrl?: string;
    linkDocument?: imageFile;
}
interface componentCard {
    internalName?: string;
    imageUrl: imageFile;
    backgroundGradientColor?: string;
    imagePosition?: string;
    linkText?: string;
    imageLinkDocument?: imageFile;
    imageLinkUrl?: string;
    cardTitle?: string;
    cardDescription?: Document;
    links?: link[];
    images?: imageFile[];
}
interface cardProps {
    displayTile?: string;
    card?: componentCard;
    isRelatedContent?: boolean;
    title?: string;
}

export const ComponentCard = ({ card, displayTile, isRelatedContent, title }: cardProps) => {
    let OrangeLinearGradient: string = `linear-gradient(to bottom, rgba(242, 101, 34, 0.8) 0%, rgba(253, 183, 26, 0.9) 75%, white 75%)`
    let GreenLinearGradient: string = `linear-gradient(to bottom, rgba(38, 188, 180, 0.9) 0%, rgba(116, 237, 149, 0.8) 75%, white 75%)`
    // let navigate = useNavigate();
    const gradient = (color: string) => {
        let gradientCss;
        switch (color) {
            case 'Orange': gradientCss = 'from-orange to-orangeLite'
                break;
            case 'Green': gradientCss = 'from-green to-greenLite'
                break;
            default:
                break;
        }
        return gradientCss;
    }

    const handleDownload = (url : string, filename: string) => {
        if(url) {
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = filename || "downloaded-file";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {});
        }
    };

    const handleLinkDownload = (linkval: link) => {
        let linkurl = linkval?.linkUrl ? linkval.linkUrl : linkval.linkDocument?.file.url
        let filename = linkval?.linkDocument?.file?.fileName
        handleDownload(linkurl as string, filename as string)
    };
    return (

        <div className=" py-3">
            {(card && (card?.imagePosition === "None") && displayTile === "Single tile"
                && !isRelatedContent) && (
                    <div className='desk:px-6 desk:ml-[3%] max-tab:px-3'>
                        <div className={`desk:w-[48%] pb-3 desk:mt-[1%]`}>
                            <img src={card?.imageUrl?.file?.url} alt={card?.imageUrl?.file?.fileName} />
                        </div>
                        <div>
                            <button className="bg-[#0b5fff] font-MarkBold text-white font-semibold py-[0.4375rem] px-[0.9375rem] rounded text-[1rem]" onClick={() =>handleDownload(card?.imageLinkDocument?.file?.url as string, card?.imageLinkDocument?.file?.fileName as string)}>
                                {card?.linkText}
                            </button>
                            {/* </a> */}
                        </div>
                    </div>
                )}

            {(card && (card?.imagePosition === "Right")) && (
                <>
              {/*   mobile view */}
                    <div className={`md:hidden px-6`} style={{background:card.backgroundGradientColor === 'Orange' ? OrangeLinearGradient: GreenLinearGradient}}>
                        <div className="px-8 pt-8 pb-4">
                            <h3 className="text-darkText text-[2rem]">
                                {card?.cardTitle}
                            </h3>
                            <DisplayContent value={card?.cardDescription as Document} heading='' />
                        </div>
                        <div className="cursor-pointer pt-4 pb-8 px-8">
                            <img width={'100%'} src={card?.imageUrl?.file?.url}
                                alt={card?.imageUrl?.file?.fileName}
                                 />
                        </div>
                    </div>

                {/*  desktop view */}
                    <div 
                    className={`hidden bg-gradient-to-b  ${gradient(card?.backgroundGradientColor as string)} md:flex p-8 font-[MarkPro-Light SKY] mx-[1%]`}>
                        <div className="p-3 w-[52%]">
                            <h4 className={`font-semibold  text-left text-[1.5rem] text-darkText`}>
                                {card?.cardTitle}
                            </h4>
                            <DisplayContent value={card?.cardDescription as Document} heading='' />
                        </div>
                        <div className={`p-3 ${card?.imageLinkUrl ? 'cursor-pointer' : ''} w-[48%]`}>
                            <a href={card?.imageLinkUrl}>
                                <img src={card?.imageUrl?.file?.url}
                                    alt={card?.imageUrl?.file?.fileName} />
                            </a>
                        </div>
                    </div>
                </>
            )}

            {(card && (card?.imagePosition === "None") && !isRelatedContent && 
            (displayTile === "Four tile" || displayTile === "Three tile") ) && (
                <div className="grid">
                    {card?.imageUrl &&
                        (<div className="p-3 cursor-pointer ">
                            <a href={card?.imageLinkUrl ? card?.imageLinkUrl : card?.imageUrl?.file?.url}>
                                <img src={card?.imageUrl?.file?.url}
                                alt={card?.imageUrl?.file?.fileName} style={displayTile === "Three tile" ? {height:'18rem', width:'auto', 
                                objectFit:'contain'} : {}}/>
                            </a>
                        </div>)}
                        {
                            card?.images && (
                                <div className='flex flex-col'>
                                    {
                                        card?.images.map((item) => {
                                            return (
                                                <div className="p-3 cursor-pointer ">
                                                    <a href={item?.file?.url}>
                                                        <img src={item?.file?.url}
                                                            alt={item?.file?.fileName} />
                                                    </a>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        
                            
                        }
                    {card?.links && card?.links.map((link: link) => {
                        return (
                            <div className="flex  justify-center py-2">
                                {link?.linkText && <button className="bg-[#0b5fff] text-white font-MarkBold font-bold py-[0.4375rem] px-[0.9375rem] rounded text-[1rem]"
                                    onClick={() => handleLinkDownload(link)}>
                                    {link?.linkText}
                                </button>}
                                {/* </a> */}
                            </div>
                        )
                    })}
                    {(displayTile === "Three tile") ?<h4 className={`font-bold text-left text-[2rem] desk:pl-3`}>
                        {card?.cardTitle}
                    </h4> :<h1 className={`font-bold text-left text-3xl desk:pl-3`}>
                        {card?.cardTitle}
                    </h1>}
                    <div className='desk:pl-3'>
                    <DisplayContent value={card?.cardDescription as Document} heading="" /></div>
                </div>
            )}

            {(card && (card?.imagePosition === "None") && isRelatedContent) && (

                <>
                    {/* mobile view */}


                    <div className="md:hidden px-6">
                        <div className="flex flex-col">

                            {card?.imageUrl && (
                                <div className="">
                                    <img src={card?.imageUrl?.file?.url}
                                        alt={card?.imageUrl?.file?.fileName}
                                        />
                                </div>
                            )}
                            <div className="my-5">
                                <div className="mb-5">
                                    <h1 className={`font-bold text-left text-[2.5rem] text-darkText`}>
                                        {card?.cardTitle}
                                    </h1>
                                </div>
                                {card?.links && card?.links.map((link: link, i: number) => {
                                    return (
                                        <div className="my-3">
                                            {(i > 0) && <hr className="h-px mb-3 bg-slate border-0 dark:bg-slate"></hr>}
                                            <a href={link?.linkUrl} 
                                                className="text-textBlue text-[1.5rem]">
                                                {link?.linkText}
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="retail-office-content-mobile">
                            <DisplayContent value={card?.cardDescription as Document} heading="" isRelatedContent={isRelatedContent} />
                        </div>
                    </div>
                    {/* desktop view */}
                    <div className="hidden md:block px-6">
                        <div className="grid grid-cols-12">
                            <div className="col-span-1"></div>
                            {card?.imageUrl &&
                                (<div className={`px-3 ${card?.imageLinkUrl ? 'cursor-pointer' : ''} col-span-8`}>
                                <a href={card?.imageLinkUrl} >
                                    <img src={card?.imageUrl?.file?.url}
                                        alt={card?.imageUrl?.file?.fileName}
                                        width={'100%'} />
                                </a>
                                </div>)}
                            <div className="flex flex-col w-max px-3">
                                <div><h1 className={`text-left text-[2rem] font-MarkBook font-semibold text-darkText mb-[0.9375rem]`}>
                                    {card?.cardTitle}
                                </h1></div>
                                <div>
                                {card?.links && card?.links.map((link: link, i: number) => {
                                    return (

                                        <div className={i===0 ? "flex items-center" : ""}>
                                            {/* horizontal line */}
                                            {(i > 0) && <hr className="h-px bg-slate border-0 dark:bg-slate w-50 my-[0.5rem]"></hr>}
                                            <a href={link?.linkUrl}
                                                className="text-linkBlue text-[1.125rem] font-MarkBook hover:text-textBlue hover:underline">
                                                {link?.linkText}
                                            </a>
                                        </div>
                                    )
                                })}
                                </div>
                            </div>

                        </div>
                        <div className="retail-office-content">
                            <div className='w-[8.3333%] px-3'></div>
                        {card?.cardDescription && card?.cardDescription?.content.map((item) => {
                            return (
                            <DisplayContent value={item?.data?.target?.fields?.content as Document} heading={item?.data?.target?.fields?.heading} isRelatedContent={isRelatedContent} />
                            )
                        })}
                        </div>
                    </div>
                </>

            )}

            {(card && (card?.imagePosition === "Left" || displayTile === "Two tile")) && (
                <div className={`px-2 pr-12 ${(displayTile==="Two tile")?"":"ml-[4%]"}`}>
                {title && <h3 className={`font-semibold text-left text-[2.5rem] my-[2%] pl-2`}>
                            {title}
                        </h3>}
                <div className={`${(displayTile==="Two tile")? "grid grid-cols-2" : "flex flex-row"} `}>
                <div className={`p-3 ${(displayTile==="Two tile")? "w-full":"w-[25%]" } ${card?.imageLinkUrl ? 'cursor-pointer' : ''}`}>
                    <a href={card?.imageLinkUrl}>
                        <img src={card?.imageUrl?.file?.url}
                            alt={card?.imageUrl?.file?.fileName} />
                    </a>
                </div>
                    <div className="p-1">
                        <h5 className={`font-normal text-left text-[1.5rem]`}>
                            {card?.cardTitle}
                        </h5>
                        <DisplayContent value={card?.cardDescription as Document} heading='' />
                    </div>
                </div>
                </div>
            )}
        </div>
    )
}