
import { useState, useEffect } from "react";
import { useQuery } from '@apollo/client';
import { getResourceSet } from "../../graphql/queries/businessComposePage";
import { ResourceSet } from "../resourceSet";
// import { EmailTemplate } from "../email";
interface HeadingProps {
    value: String;
    fontSize: String;
    fontFamily: String;
}
interface ParagraphProps {
    value: String;
    fontSize: String;
}
const  HeadingTag = ({ value, fontSize, fontFamily }: HeadingProps) => {

    return (
        <h1 className={`font-semibold text-darkText text-left ${fontSize} px-4 py-3 ${fontFamily}`}>
            {value}
        </h1>
    )
}

const ParaTag = ({ value }: ParagraphProps) => {
    return (
        <p className="text-left text-darkText pl-4 text-[1rem] font-MarkLight whitespace-pre-wrap ">
            {value}
        </p>
    )
}
interface contactContent {
    type: string;
    value: string
}
interface contact {
    heading: string;
    subHeading: string;
    content: contactContent[]
}

interface CaptchaErr {
    err: boolean,
    msg: string,
}
interface ContactProps {
    value: contact,
    splitContact: Boolean,
    onSubmit: any,
    captchaErr: CaptchaErr,
}

interface ReqProps {
    onFormSubmit: any
    captchaErr: CaptchaErr
}
const RequestCallBack = ({onFormSubmit, captchaErr} : ReqProps) => {
    const [reqCallBack, setReqCallBack] = useState<any>()
    // const [formVal, setFormVal ] = useState<any>()
    const { data } = useQuery(getResourceSet, {
        variables: {
            usePreview: false,
            title: "Request a call back"
        }
    });
    useEffect(() => {
        if (data) {
            setReqCallBack(data?.BusinessResourceSet?.[0])
        }
    }, [data])

    const onReqFormSubmit = async (value:any) => {
        onFormSubmit(value,"Call Back Query" )
        // setFormVal(value)
    }
    return (
        <>
            <ResourceSet value={reqCallBack} onSubmitClick={onReqFormSubmit} captchaErr={captchaErr}
            isCaptchaReq={true}/>
        </>
    )
}
const ContactUs = ({ value, splitContact, onSubmit, captchaErr }: ContactProps) => {

    return (
        // contact us section start
        <div className="flex px-1 mb-14 max-tab:flex-col desk:flex-row">
            <div className="basis-1/2">
                <HeadingTag value={value?.heading} fontSize='text-[2rem]' fontFamily="font-MarkBlack"/>
                <ParaTag value={value?.subHeading} fontSize='text-md' />
                {
                    value && value?.content.map((val: contactContent, i: number) => {
                        
                        if (!splitContact ||
                            ((!val?.value.toLowerCase().includes('postal') && !val?.value.toLowerCase().includes('po')) && splitContact)) {
                            if (val.type === "paragraph") {
                                return (
                                    <ParaTag value={val.value} fontSize='text-md' />
                                )
                            } else {
                                return (
                                    <HeadingTag value={val.value} fontSize='text-[1.5rem]' fontFamily="font-MarkBold" />
                                )
                            }
                        }return ''

                    })
                }
            </div>
            <div className="basis-1/2 lg:mx-auto">
                {
                    splitContact && value && value.content.map((val: contactContent, i: number) => {
                        if (val.value.toLowerCase().includes('postal') || val.value.toLowerCase().includes('po')) {
                            if (val.type === "paragraph") {
                                return (
                                    <ParaTag value={val.value} fontSize='text-md' />
                                )
                            } else {
                                return (
                                    <HeadingTag value={val.value} fontSize='text-2xl' fontFamily={'MarkBold'} />
                                )
                            }
                        }return ''

                    })
                }
                {
                    // request a call back form append
                    !splitContact && <RequestCallBack onFormSubmit={onSubmit} captchaErr = {captchaErr}/>

                }
            </div>
        </div>
    )
}

export { ContactUs }