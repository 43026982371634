import { gql } from '@apollo/client'
import { BusinessMenuList } from './header';

export const Contact = gql`
fragment Contact on ContactInfo {
    internalName
    heading
    subHeading
    content{
      type
      value
    }
}
`;

export const ResourceSet = gql `
fragment ResourceSet on BusinessResourceSet {
  internalName
  formTitle
  formSubTitle
  resources{
    key
    value
    required
  } 
}
`

export const CardImage = gql`
fragment CardImage on BusinessCardImage {
  file{
    url
    fileName
    contentType
  }
  title
  description
}
`
export const ComposePage = gql `
fragment ComposePage on BusinessComposePage {
  internalName
    slug
    pageTitle
    content{
      businessContent{
        sitemap{
...BusinessMenuList
        }
        heroBanner{
          bannerImage
          bannerTitle
          bannerPhoneNumber
          exploreScrollContent
          exploreScrollHeading
          headingPosition
          exploreLinkText
          exploreLinkUrl
        }
        freeformContent {
          heading
          content
        }
        resourceSet {
          ...ResourceSet
        }
        componentSection{
          internalName
          componentSectionTitle          
          displayTile
          isRelatedContentSection
          contentType
          components{
            internalName
            isSlider
            contentType
            cards {
              imageUrl
              description
              linkText
              linkUrl
              title
              gradientColor
              internalName
            }
            imageUrl{
              ...CardImage
            }
            imagePosition
            backgroundGradientColor
            linkText
            imageLinkDocument{
              ...CardImage
            }
            imageLinkUrl
            cardTitle
            cardDescription
            links {
              linkText
              linkUrl
              linkDocument {
                ...CardImage
              }
            }
              images{
              ...CardImage
            }
          }
        }
      }
    }
    requestACallBackFormRequired
    contactUsSectionRequired
}
${ResourceSet}
${CardImage}
${BusinessMenuList}
`
