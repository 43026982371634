import { DisplayContent } from "../displayContent"
import { Document } from '@contentful/rich-text-types';

interface BannerProps {
  bannerImage?: string;
  bannerTitle?: string;
  bannerPhoneNumber?: string;
  exploreScrollContent?: Document;
  exploreScrollHeading?: string;
  headingPosition?: string;
  exploreLinkText?: string;
  exploreLinkUrl?: string;
}
type exploreFunction = () => void;
interface Props {
  banner?: BannerProps,
  onClickExplore?: exploreFunction,
  exploreRef?: any
}

export const Banner = ({ banner, onClickExplore, exploreRef }: Props) => {

  //function to make position css dynamc as per value coming from contentful
  const textPosition = (position: string) => {
    let displayCss;
    switch (position) {
      case 'Center': displayCss = 'text-center'
        break;
      case 'Left': displayCss = 'text-left'
        break;
      default:
        break;
    }
    return displayCss;
  }

  return (
    <>
      {/* hero banner- contain banner image, title and phone number and explore arrow */}
      <div className="w-full h-screen">
        <div className="w-full h-screen  relative" >
          {banner?.bannerImage && <img className="w-full h-screen block object-cover object-[top_center] origin-[100%_0]" 
          src={banner?.bannerImage} alt="banner_image"></img>}
          <div className="absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.5)]"></div>
          <div className="absolute font-[MarkPro-Light SKY] tab:max-w-[50%] left-[5%] bottom-[21%] tab:left-[10%]">
            <h2 className="desk:text-[4.25rem] max-desk:text-[2.8125rem] max-desk:font-MarkLight  text-whiteText leading-none tracking-[normal]"><div>{banner?.bannerTitle}</div></h2>
            <h3 className="desk:text-[3.125rem] mt-[1rem] max-desk:text-[2.1875rem] leading-none tracking-[normal] text-headingYellow "><div>{banner?.bannerPhoneNumber}</div></h3>
          </div>

          <div>
            <a href="#explore" onClick={onClickExplore} className="absolute ml-[50%] z-10 text-whiteText tracking-widest no-underline pt-[60px] transition-[opacity_0.3s] -translate-x-1/2 bottom-14 desk:bottom-[4.5rem] cursor-pointer hover:opacity-50 "
            data-testid="explore">{banner?.exploreLinkText}
              <span className="absolute top-0 left-1/2 w-6 h-6 -ml-3 border-l border-b border-l-whiteText -rotate-45 border-b-whiteText animate-arrow"></span>
            </a>
          </div>

        </div>


      </div>

      {/* explore content -contains heading and description*/}
      {banner?.exploreScrollContent && banner?.headingPosition === "Center" && (
        <div id="explore" ref={exploreRef} className={`${textPosition(banner?.headingPosition)} py-12 px-4  flex items-center justify-center`}>
          <div className="max-desk:w-[90%] desk:w-3/6 text-darkText font-[MarkPro-Book SKY]">
            <div className="text-[2.5rem] mb-[0.5rem] font-semibold"><span>{banner?.exploreScrollHeading}</span></div>
            <div><DisplayContent value={banner?.exploreScrollContent} heading="" /></div>
          </div>
        </div>
      )}
      {banner?.exploreScrollContent && banner?.headingPosition === "Left" && (
        <div id="explore" ref={exploreRef} className={`${textPosition(banner?.headingPosition)} py-12  flex items-center justify-center`}>
          <div className="desk:flex max-desk:block items-start justify-center desk:w-full text-darkText font-[MarkPro-Light SKY]">
            {(window.location.href.includes("signage") || window.location.href.includes("retail")) ?
              <h3 className="text-[2.5rem] leading-[1.2] font-semibold font-[MarkPro-Book SKY] mb-[2rem] max-tab:w-full desk:w-[38%] px-3">
                {banner?.exploreScrollHeading}</h3>
              :
              <div className="text-[2.6rem] mb-[2rem] max-tab:w-full desk:w-[42%] px-3 font-semibold">
                <span>{banner?.exploreScrollHeading}</span>
              </div>

            }
            
            <div className="desk:w-[40.5%]  max-tab:w-full px-3">
              <DisplayContent value={banner?.exploreScrollContent} heading="" />
            </div>
          </div>
        </div>
      )}
    </>
  )
}