
import React, { ReactNode } from 'react';
import { Document, BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';

export interface ContentProps {
  value: Document;
  heading: string;
  isRelatedContent?: boolean;
  path?: string;
}

export const DisplayContent = ({ value, heading, isRelatedContent, path }: ContentProps) => {
  
  let tableProps = {
    style: { borderWidth: '1px' },
    className: `${(window.location.href?.includes('terms-and-conditions')) ? 'border-none' : ''} border-collapse border-black text-left`,
  };

  let relatedTableProps = {
    style: { borderWidth: '1px' },
    className: 'text-relatedOrange border-none border-collapse text-left',
  };

  let relatedTablePropsCell = {
    style: { borderWidth: '1px' },
    className: ' text-darkText border-none border-collapse border-black text-left',
  }
  let aboutBillClass = 'border-separate border-spacing-1 border border-black text-left'

  const tableBodyStyle = {
    style: { ...tableProps.style },
    className: `${(isRelatedContent || (path?.includes('terms-and-conditions'))) ? relatedTablePropsCell.className :
      ((path?.includes('bill')) ? aboutBillClass : tableProps.className)} ${(path?.includes('bill')) ? '' : 'py-2 mt-[30px]'} w-full`,
  };
  let paraStyle = "text-[1rem] font-MarkBook"
  const renderOptions: Options = {
    renderText: (text: string) =>
      text
        .split('\n')
        .reduce(
          (children: any, textSegment: any, index: number) => [
            ...children,
            index > 0 && <br key={index} />,
            textSegment,
          ],
          []
        ),

    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => <b className={`text-[1rem]`}>{text}</b>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (_, children: ReactNode) => (
        <h1 className={`text-darkText font-semibold mt-4 font-[MarkPro-Light SKY] text-5xl`}>{children}</h1>
      ),
      [BLOCKS.HEADING_5]: (_, children: ReactNode) => (
        <h5 className={`text-darkText font-semibold mt-4 font-[MarkPro-Light SKY] text-[1.625rem]`}>{children}</h5>
      ),
      [BLOCKS.HEADING_6]: (_, children: ReactNode) => (
        <h6 className={`text-darkText font-semibold mt-4 font-[MarkPro-Light SKY] text-[1.188rem]`}>{children}</h6>
      ),
      [BLOCKS.HEADING_2]: (_, children: ReactNode) => (
        <h2 className="text-darkText font-semibold text-[2.5rem]">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (_, children: ReactNode) => (
        <h3 className={`text-[2rem] text-darkText font-semibold mb-2 mt-4`}>{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (_, children: ReactNode) => (
        <h4 className={`py-2 text-darkText mx-auto text-2xl`}>{children}</h4>
      ),
      [BLOCKS.PARAGRAPH]: (_, children: ReactNode) => <p className={!isRelatedContent ? 
        `text-darkText text-[1rem]` : paraStyle}>{children}</p>,
      [INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
        <a href={node?.data?.uri} className="text-linkBlue hover:underline" target={node?.data?.uri.includes("http")?"_blank":""}>
          {children}
        </a>
      ),
      [BLOCKS.OL_LIST]: (_, children: ReactNode) => (
        <div className="desk:px-6 pb-8 lg:px-0 mx-auto">
          <ol className="text-lg text-black orderedList desk:pl-10">{children}</ol>
        </div>
      ),
      [BLOCKS.UL_LIST]: (_, children: ReactNode) => <ul className="text-lg text-black unorderedList pl-6">{children}</ul>,
      [BLOCKS.TABLE]: (_, children: ReactNode) => (
        <table {...tableBodyStyle} >
          <tbody>{children}</tbody>
        </table>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (_, children: ReactNode) => <th {...(isRelatedContent ? relatedTableProps : tableProps)}  >{children}</th>,
      [BLOCKS.TABLE_ROW]: (_, children: ReactNode) => <tr {...(isRelatedContent ? relatedTablePropsCell : tableProps)}>{children}</tr>,
      [BLOCKS.TABLE_CELL]: (_, children: ReactNode) => <td {...(isRelatedContent ? relatedTablePropsCell : tableProps)}>{children}</td>,
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        // target the contentType of the EMBEDDED_ENTRY to display as you need
        if (node.data.target.sys.contentType.sys.id === "freeformContent") {
          return (
            <div>
              <DisplayContent value={node?.data?.target?.fields?.content} heading={node?.data?.target?.fields?.heading} />
            </div>
          );
        }
      },
    },
  };

  return (
    <>
      {isRelatedContent ? (
        <div className="flex flex-col">
          <div><p className="text-relatedOrange font-md text-[1rem] mb-[3%] font-MarkBook">{heading}</p ></div>
          {
            documentToReactComponents(value, renderOptions)
          }
        </div>
      ) : <>
        <div><h1 className="text-darkText font-semibold text-[3rem]">{heading}</h1></div>
        {

          documentToReactComponents(value, renderOptions)
        }</>
      }
    </>
  )
}