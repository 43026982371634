import { useNavigate } from "react-router-dom";
interface links{
  itemUrl:string;
  publicName: string;
}
interface sitemap{
  slug?:string;
  itemUrl?: string;
  sectionName?:string;
  publicName?:string;
  sectionLinks?:links[];
}
interface Sitemaps{
  value?: sitemap[]
}
export const Sitemap = ({ value }: Sitemaps) => {
  let navigate = useNavigate();
  return (
    // Appending site map list items and sub list items
    <div className="ml-20 text-lg mt-[2%]">
      {value && value?.map((item: sitemap) => (
        <ul className="unorderedList">
          <li className=" cursor-pointer hover:underline  "
            onClick={() => (navigate((item?.slug ? item?.slug : item?.itemUrl) as string))}
            key={item?.sectionName ? item?.sectionName : item?.publicName}>
            <p className="text-linkBlue">{item?.sectionName ? item?.sectionName : item?.publicName}</p></li>
          <ul className="unorderedList">{item?.sectionLinks && item?.sectionLinks?.map((sub: any) => {
            return <li className=" cursor-pointer hover:underline  "
              onClick={() => (navigate(`${sub?.itemUrl}`))}
              key={sub?.publicName}>
              <p className="text-linkBlue">{sub?.publicName}</p></li>;
          })}</ul>
        </ul>
      ))}

    </div>
  )
}