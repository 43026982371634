import React from 'react';
import { errorPageIcon } from './icon';
import {ErrProps} from '../helpers/types'


interface ErrorProps {
  errorMessage: ErrProps
}

const ErrorContainer = ({errorMessage }: ErrorProps) => {
  return (
    <div
      className="flex flex-col justify-center md:flex-row md:w-2/3 md:px-0 md:items-center md:m-auto px-4 h-screen align-middle self-center"
      style={{ height: `calc(100vh - 4rem)` }}>
      <div className="md:w-1/2 w-full">
        <span className="flex algin-center justify-center">{errorPageIcon}</span>
      </div>
      <div className="md:w-1/2 w-full pt-8 md:pt-0">
        <h1 className="text-center md:text-left sky-h1 md:sky-h1 text-blue-light font-bold pb-8">{errorMessage?.headerText}</h1>
        {errorMessage?.lineOne && <h4 className="sky-h4 md:sky-h4 font-bold text-center md:text-left">{errorMessage.lineOne}</h4>}
        {errorMessage?.lineTwo && <h4 className="sky-h4 md:sky-h4 font-bold text-center md:text-left">{errorMessage.lineTwo}</h4>}
      </div>
    </div>
  );
};

export { ErrorContainer };
