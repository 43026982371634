// import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { gradients } from "../../config/config";

interface T_Popup {
  imageUrl?: string;
  description?: string;
  linkText?: string;
  linkUrl?: string;
  title?: string;
  gradientColor?: string;
  internalName?: string;
}

interface IWhatsHotProps {
  popupCards: T_Popup[];
  isCarousel?: boolean;
  isSlider?: boolean;
  displayTile?: string
}
interface IWhatsHotCardProps {
  backgroundColorGradient?: string;
  popUpCard?: T_Popup;
  // onClickPopupCard?: any;
  tileCss?: string
}

export const PopUpCard = ({ popupCards, isSlider, displayTile }: IWhatsHotProps) => {
  // let navigate = useNavigate()

  //placeholder until the data for the cards is available, then will assign a different color to each card.

  function assignBackgroundGradient(color: string) {
    let backgroundColorGradient: string = '';
    gradients.forEach((gradient) => {
      if (gradient.includes(color.toLowerCase()))
        backgroundColorGradient = gradient
    })
    return backgroundColorGradient;
  }

  // setting css of popup cards  based on how many cards tiles to show in a line
  let tileCss: string
  switch (displayTile?.replace(' ', '')) {
    case 'Twotile':
      tileCss = 'grid-cols-2';
      break;

    case 'Threetile':
      tileCss = 'grid-cols-3';
      break;

    case 'Fourtile':
      tileCss = 'grid-cols-4 gap-0';
      break;

    default:
      tileCss = 'grid-cols-1 gap-0';
      break;
  }

  const WhatsHotCard = ({ backgroundColorGradient, popUpCard }: IWhatsHotCardProps) => {
    return (
      <>
        <div
          className="w-[100%]  flex-none relative 
                              md:flex-grow md:overflow-hidden overflow-hidden group">
          <div className={`object-cover w-full h-full`}>
            <img className="w-full h-[25rem] object-cover block"
             src={popUpCard?.imageUrl} alt={popUpCard?.linkText} />
            <div className="absolute bottom-0 sky-text-white overflow-auto m-4 z-10">
            <a href={popUpCard?.linkUrl} target={popUpCard?.linkUrl?.includes("http")?"_blank":""} rel="noreferrer">
              <h3 className="text-[1rem] font-semibold cursor-pointer">{popUpCard?.linkText}</h3>
              </a>
            </div>
          </div>

          {isSlider && <Silder popUpCard={popUpCard} backgroundColorGradient={backgroundColorGradient} />}
        </div>
      </>
    );
  };

  const Silder = ({ popUpCard, backgroundColorGradient }: IWhatsHotCardProps) => {
    return (
      // overlay of popupcards when hovering
      <>
        <a href={popUpCard?.linkUrl} target={popUpCard?.linkUrl?.includes("http")?"_blank":""} rel="noreferrer">
          <div
            className={`absolute top-0 left-0 h-full w-full 
                      overflow-hidden ${backgroundColorGradient}
                      transform -translate-x-full group-hover:translate-x-0
                      transition duration-500 flex flex-col items-start opacity-70 cursor-pointer`}
          >

            {/* <div className="absolute bottom-0 right-0 mr-3 mb-3">
            <img className="h-auto w-16" src={popUpCard?.popupCardIconURL} alt="channel logo" />
          </div> */}
          </div>
          <div className="absolute top-0 sky-text-white flex flex-col pt-4 px-4 overflow-auto h-85% mb-14 z-50 transform -translate-x-full group-hover:translate-x-0
                      transition duration-500 cursor-pointer ">
            <div className="mt-2 overflow-auto">
              <p className="text-[1rem] text-whiteText">{popUpCard?.description}</p>
            </div>
          </div>
        </a>
      </>
    );
  };

  return (
    <>
    {/* common popup cards */}
      {(
        <div className='w-full'>
          <div className={`w-full mx-auto flex flex-col  grid lg:${tileCss} pb-8 flex-no-wrap my-0 sm:flex-wrap sm:flex-row sm:overflow-hidden`}>
            {popupCards?.map((item, i) => (
              <WhatsHotCard key={i} popUpCard={item} backgroundColorGradient={assignBackgroundGradient(item?.gradientColor as string)} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
