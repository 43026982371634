export const errorPageIcon = (
    <svg width="236" height="195" viewBox="0 0 236 195" fill="none">
      <path
        d="M0 98.227C0 110.885 4.94764 122.787 13.9342 131.746C22.9207 140.704 34.8606 145.636 47.5579 145.636H87.5934C88.7293 145.636 89.84 145.183 90.6478 144.378C91.4556 143.573 91.9099 142.491 91.9099 141.333V120.145C91.9099 118.308 90.4206 116.848 88.6031 116.848H73.0029C71.1602 116.848 69.6961 118.333 69.6961 120.145V123.441H47.5831C33.5985 123.441 22.2896 112.143 22.2896 98.227V97.4972C22.2896 83.5562 33.6237 72.2827 47.5831 72.2827H69.6961V78.926C69.6708 80.7378 71.1602 82.1974 72.9777 82.1974H88.5779C90.4206 82.1974 91.8847 80.7127 91.8847 78.9008V54.3909C91.8847 53.2585 91.4303 52.1513 90.6226 51.346C89.8148 50.5408 88.7293 50.0878 87.5681 50.0878H47.5579C34.8606 50.0878 22.9207 55.02 13.9342 63.9785C4.94764 72.9369 0 84.8396 0 97.4972"
        fill="#00013A"
      />
      <path
        d="M151.988 21.3755C150.903 20.7967 149.691 20.4948 148.48 20.4948C145.652 20.4948 143.078 22.0298 141.765 24.521L128.613 49.4336C126.67 53.1076 128.083 57.6874 131.794 59.6251C132.879 60.2039 134.091 60.5058 135.303 60.5058C138.13 60.5058 140.705 58.9708 142.017 56.4796L155.169 31.567C156.103 29.7803 156.305 27.742 155.699 25.8044C155.093 23.8919 153.781 22.3066 151.988 21.3755Z"
        fill="#00B1EB"
      />
      <path
        d="M109.025 18.2803C108.04 15.1096 105.137 12.9706 101.805 12.9706C101.048 12.9706 100.291 13.0965 99.5587 13.3229C95.5703 14.556 93.3236 18.8087 94.5606 22.7847L102.941 49.6601C103.926 52.8307 106.829 54.9697 110.161 54.9697C110.918 54.9697 111.675 54.8439 112.407 54.6174C114.351 54.0135 115.916 52.7049 116.875 50.9183C117.809 49.1316 118.011 47.0933 117.406 45.1557L109.025 18.2803Z"
        fill="#00B1EB"
      />
      <path
        d="M147.571 73.0628C148.555 76.2335 151.458 78.3724 154.79 78.3724C155.547 78.3724 156.305 78.2466 157.037 78.0201L183.996 69.6656C187.985 68.4326 190.231 64.1798 188.969 60.229C187.985 57.0583 185.082 54.9194 181.75 54.9194C180.993 54.9194 180.235 55.0452 179.503 55.2717L152.544 63.601C148.555 64.8341 146.334 69.0868 147.571 73.0628Z"
        fill="#00B1EB"
      />
      <path
        d="M172.032 194.052C184.729 194.052 196.669 189.12 205.655 180.162L206.185 179.633C215.172 170.675 220.12 158.772 220.12 146.114C220.12 133.457 215.172 121.554 206.185 112.596L177.863 84.4118C177.055 83.6066 175.969 83.1536 174.808 83.1536C173.672 83.1536 172.562 83.6066 171.754 84.4118L156.734 99.3845C155.447 100.668 155.447 102.782 156.734 104.065L167.765 115.062C169.053 116.345 171.173 116.345 172.461 115.062L174.808 112.722L190.459 128.323C200.329 138.163 200.329 154.142 190.459 164.006L189.929 164.535C180.059 174.374 164.029 174.374 154.134 164.535L138.483 148.933L143.204 144.227C144.517 142.919 144.517 140.83 143.204 139.521L132.173 128.525C130.885 127.241 128.765 127.241 127.478 128.525L110.11 145.863C109.303 146.668 108.848 147.75 108.848 148.908C108.848 150.04 109.303 151.147 110.11 151.953L138.408 180.162C147.394 189.12 159.334 194.052 172.032 194.052Z"
        fill="#00013A"
      />
    </svg>
  );