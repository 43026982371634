import React from 'react';
// 404 not found image to show 
export const NotFound = () => {
  return (
    <svg viewBox="0 0 538 271" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="-112" width="618" height="422">
          <rect
            width="570.772"
            height="235.754"
            transform="translate(8.21997 88.4463) rotate(-20.4856)"
            fill="white"></rect>
        </mask>
        <g mask="url(#mask0)">
          <path
            d="M158.528 131.012C126.529 142.966 97.0633 130.834 65.2902 140.055C33.5172 149.276 -0.317901 202.823 -40.4605 224.053C-80.603 245.284 -155.43 226.022 -196.454 241.348"
            stroke="#00013A"
            strokeWidth="13.6769"
            strokeMiterlimit="10"></path>
          <path
            d="M223.121 108.282L210.111 66.7824C210.111 66.7824 198.556 71.0993 191.513 73.7304C180.368 77.8941 182.938 99.9972 174.059 110.015C166.667 118.387 158.625 121.469 153.976 123.206C149.258 124.969 152.135 133.711 152.135 133.711C152.135 133.711 155.695 142.199 160.413 140.436C165.13 138.674 173.25 135.796 184.225 137.228C197.498 138.97 210.119 157.318 221.195 153.18C228.238 150.549 239.793 146.232 239.793 146.232L222.891 108.29L223.121 108.282Z"
            fill="#00B1EB"></path>
          <path
            d="M246.694 98.3844C241.483 84.4363 234.826 72.6648 228.411 65.0881L223.711 52.5075L213.045 56.4924C204.293 59.762 205.246 83.7938 215.08 110.117C224.94 136.509 240.021 155.185 248.704 151.941L259.371 147.956L254.67 135.375C254.597 125.585 251.905 112.332 246.694 98.3844Z"
            fill="#00013A"></path>
          <path
            d="M256.14 94.8553C261.05 107.998 263.725 120.536 264.09 130.174C264.273 134.995 263.877 139.062 262.914 142.107C261.948 145.16 260.443 147.099 258.469 147.837C256.495 148.574 254.087 148.097 251.356 146.425C248.632 144.758 245.667 141.947 242.643 138.186C236.6 130.669 230.399 119.449 225.489 106.307C220.578 93.164 217.904 80.626 217.538 70.9882C217.355 66.1665 217.751 62.0998 218.715 59.0547C219.68 56.0022 221.185 54.0626 223.16 53.325C225.134 52.5875 227.542 53.0652 230.272 54.7367C232.996 56.4042 235.962 59.2151 238.985 62.9756C245.028 70.4924 251.23 81.7126 256.14 94.8553Z"
            fill="white"
            stroke="#00013A"></path>
          <path
            d="M236.25 82.1063C237.527 85.5249 237.685 88.6603 236.523 89.0945C235.361 89.5288 233.424 87.0576 232.147 83.6389C230.87 80.2203 230.712 77.0849 231.874 76.6507C233.036 76.2164 234.972 78.6876 236.25 82.1063Z"
            fill="#00013A"></path>
          <path
            d="M265.719 64.0066L231.874 76.6509L236.497 89.0264L270.342 76.3821L265.719 64.0066Z"
            fill="#00013A"></path>
          <path
            d="M271.393 76.0671C274.829 74.7833 276.574 70.9569 275.291 67.5207C274.007 64.0844 270.18 62.3394 266.744 63.6232C263.308 64.907 261.563 68.7334 262.847 72.1697C264.131 75.606 267.957 77.3509 271.393 76.0671Z"
            fill="#00013A"></path>
          <path
            d="M249.481 117.523C250.759 120.942 250.917 124.077 249.755 124.512C248.592 124.946 246.656 122.475 245.379 119.056C244.102 115.637 243.943 112.502 245.106 112.068C246.294 111.702 248.204 114.105 249.481 117.523Z"
            fill="#00013A"></path>
          <path
            d="M278.976 99.4919L245.131 112.136L249.755 124.512L283.599 111.867L278.976 99.4919Z"
            fill="#00013A"></path>
          <path
            d="M284.625 111.484C288.061 110.2 289.806 106.374 288.522 102.938C287.239 99.5014 283.412 97.7564 279.976 99.0402C276.54 100.324 274.795 104.15 276.078 107.587C277.362 111.023 281.189 112.768 284.625 111.484Z"
            fill="#00013A"></path>
        </g>
      </g>
      <path
        d="M395.108 157.743C411.9 167.68 416.97 195.954 434.417 203.394C451.863 210.834 495.585 202.443 514.75 220.569"
        stroke="#00013A"
        strokeWidth="13.6769"
        strokeMiterlimit="10"></path>
      <path
        d="M400.961 150.86C396.627 148.296 389.289 143.784 383.511 134.343C376.532 122.92 382.886 101.576 372.71 95.5542C366.24 91.7256 355.624 85.4438 355.624 85.4438L336.91 122.372L336.659 122.224L312.495 158.472C312.495 158.472 323.111 164.754 329.581 168.582C339.82 174.641 355.408 158.761 368.78 159.381C379.937 159.876 387.386 164.199 391.657 166.727C395.992 169.292 400.989 161.563 400.989 161.563C400.989 161.563 405.296 153.425 400.961 150.86Z"
        fill="#00B1EB"></path>
      <path
        d="M314.053 110.119C321.636 97.3049 330.26 86.8877 337.907 80.5572L344.746 68.9992L354.545 74.7978C362.586 79.5556 357.421 103.045 343.111 127.229C328.763 151.476 310.633 167.208 302.656 162.488L292.857 156.689L299.696 145.131C301.49 135.506 306.47 122.933 314.053 110.119Z"
        fill="#00013A"></path>
      <path
        d="M293.51 157.161C301.524 161.903 319.637 146.117 333.966 121.902C348.294 97.6874 353.414 74.2132 345.4 69.4711C337.386 64.729 319.274 80.5148 304.945 104.73C290.616 128.945 285.497 152.419 293.51 157.161Z"
        fill="#00B1EB"></path>
      <path
        d="M327.198 95.9315C325.339 99.0722 324.632 102.131 325.7 102.763C326.768 103.395 329.108 101.302 330.967 98.1617C332.825 95.0209 333.533 91.9623 332.465 91.3304C331.397 90.6985 329.056 92.7907 327.198 95.9315Z"
        fill="#00013A"></path>
      <path
        d="M307.944 128.47C306.085 131.61 305.378 134.669 306.446 135.301C307.513 135.933 309.854 133.841 311.713 130.7C313.571 127.559 314.278 124.5 313.211 123.869C312.106 123.299 309.802 125.329 307.944 128.47Z"
        fill="#00013A"></path>
      <defs>
        <clipPath id="clip0">
          <rect width="297.12" height="154.736" fill="white" transform="translate(0 103.984) rotate(-20.4856)"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};
