import { gql } from '@apollo/client';
import { Contact, ComposePage , ResourceSet} from '../fragments/businessComposePage';

export const getContactUs = gql`
  query GetContactUs($usePreview: Boolean) {
    contactUs(usePreview: $usePreview) {
    ...Contact 
    }
  }
  ${Contact}
`;

export const getComposePage = gql `
query GEtComposePage($usePreview:Boolean, $slug:String) {
  BusinessComposePage(usePreview:$usePreview, slug:$slug) {
    ...ComposePage
  }
}
${ComposePage}
`

export const getResourceSet = gql`
${ResourceSet}
  query GetResourceSet($usePreview: Boolean, $title: String) {
    BusinessResourceSet(usePreview: $usePreview, title: $title) {
    ...ResourceSet 
    }
  }
`;