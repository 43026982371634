
import { ScrollToTop } from './ScrollToTop';
import App from './App'

export function Root() {
  return (
    <>
    <ScrollToTop />
    <App />
    </>
  );
}
