
import {  useLocation } from 'react-router-dom';
interface breadcrumb {
        value?: string;
        url?: string;
}

type breadcrumbFunction = (val: string) => void;

interface Props {
    value?: breadcrumb[];
    onClickBreadcrumb: breadcrumbFunction;
}

export const Breadcrumb = ({ value, onClickBreadcrumb }: Props) => {
    const {pathname} = useLocation();
    // Don't render a single breadcrumb.
  if ((value && value?.length <= 1) || pathname?.includes('terms-of-use') || pathname?.includes('commercial-terms')
  || pathname?.includes('content-protection') || pathname?.includes('privacy-policy') || pathname === "/") {
    return null;
  }
    return (
        <div className=" absolute w-full bg-gradient-to-r from-lightGreyBread to-lightGrey h-8 px-6 z-10  flex lg:px-16">
            <ul className=" flex flex-rows items-center">
                {value && value.map((item: breadcrumb,i:number) => {
                    return(
                        <>
                        <li className="cursor-pointer hover:underline font-MarkBook text-breadcrumb flex items-center text-[0.875rem]" 
                        data-testid = {item.value}
                        key={item.value}
                        onClick={() => onClickBreadcrumb(item.url as string)}>
                          <span className='breadcrumb-text-truncate'>{item.value}</span> 
                        {(i<value?.length-1) && <span className="mx-2 text-darkText"><img height={'9.6'} width={'9.6'} 
                        alt="arrow" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='512'%20height='512'%20viewBox='0%200%20512%20512'%3E%3Cpath%20class='lexicon-icon-outline'%20d='M396.394%20255.607a28.207%2028.207%200%200%200-8.272-19.111L160.901%209.275c-11.026-11.059-28.94-11.059-39.999%200-11.058%2011.026-11.058%2028.941%200%2039.999l206.333%20206.333L120.902%20461.94c-11.058%2011.058-11.058%2028.973%200%2039.999%2011.059%2011.059%2028.972%2011.059%2039.999%200l227.221-227.221a28.196%2028.196%200%200%200%208.272-19.111z'%20fill='%rgb(0 0 10)'/%3E%3C/svg%3E"></img></span>}
                        </li>
                        </>
                    )
                })}
            </ul>
        </div>
    );
}